import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 1080.000000 1080.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >
<path d="M3120 7050 c-16 -16 -18 -25 -10 -40 6 -11 17 -20 25 -20 15 0 45 26
45 40 0 9 -28 40 -36 40 -2 0 -13 -9 -24 -20z m38 -23 c2 -12 -3 -17 -17 -17
-15 0 -21 6 -21 21 0 25 33 22 38 -4z"/>
<path d="M3945 6895 c0 -24 3 -31 12 -24 7 7 13 5 16 -3 3 -7 4 -3 4 10 -1 13
-8 29 -17 37 -13 12 -15 10 -15 -20z"/>
<path d="M4845 6890 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M1887 6778 c10 -17 10 -40 -1 -55 -5 -8 -4 -13 2 -13 6 0 13 6 16 13
5 10 7 10 12 0 3 -7 10 -13 16 -13 7 0 7 5 -1 13 -6 8 -8 16 -5 20 3 3 -4 15
-15 26 -23 23 -36 28 -24 9z"/>
<path d="M1920 6779 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1860 6750 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2482 6734 c-51 -36 -8 -117 51 -95 47 18 47 78 1 99 -31 15 -25 15
-52 -4z m53 -44 c0 -18 -6 -26 -23 -28 -13 -2 -25 3 -28 12 -10 26 4 48 28 44
17 -2 23 -10 23 -28z"/>
<path d="M3304 6680 c-338 -71 -546 -424 -444 -754 19 -61 62 -149 85 -172 5
-6 26 -30 45 -54 39 -48 155 -129 192 -132 19 -3 23 2 26 30 3 26 -1 34 -20
41 -102 42 -235 202 -263 316 -46 190 -3 363 121 497 63 68 103 95 194 131
275 109 602 -54 681 -341 37 -131 16 -294 -51 -406 -40 -66 -139 -164 -193
-189 -44 -21 -47 -25 -40 -60 5 -25 9 -28 31 -22 14 3 49 22 78 41 264 175
347 529 185 794 -72 118 -192 216 -322 261 -71 25 -231 35 -305 19z"/>
<path d="M3320 6481 c-115 -38 -205 -116 -254 -220 -28 -60 -31 -75 -31 -161
0 -86 3 -101 32 -160 34 -71 69 -115 122 -155 45 -33 58 -32 63 6 4 26 -2 37
-45 77 -59 57 -82 98 -96 179 -26 141 44 274 177 340 48 24 70 28 137 28 67 0
89 -5 137 -28 61 -30 125 -94 154 -155 26 -56 30 -168 8 -238 -14 -44 -31 -71
-76 -117 -54 -55 -58 -62 -52 -93 l6 -34 30 18 c136 79 216 274 174 424 -22
78 -63 145 -127 202 -76 68 -128 88 -239 92 -52 2 -106 0 -120 -5z"/>
<path d="M4315 6431 c5 -16 2 -21 -12 -22 -14 0 -15 -2 -3 -6 8 -3 18 -10 21
-15 3 -5 19 -2 35 7 19 10 23 15 12 15 -10 0 -27 9 -39 21 l-21 21 7 -21z"/>
<path d="M3325 6265 c-65 -42 -90 -87 -90 -159 0 -60 19 -116 41 -123 7 -3 14
21 19 64 4 37 13 82 20 99 31 74 124 99 184 49 34 -29 47 -64 56 -153 8 -68
20 -76 45 -26 23 44 19 131 -8 179 -35 62 -82 89 -161 93 -58 3 -71 0 -106
-23z"/>
<path d="M4911 6241 c-9 -6 -11 -18 -7 -36 5 -21 12 -26 34 -23 23 2 27 8 27
33 0 22 -5 31 -20 33 -11 1 -26 -2 -34 -7z m49 -26 c0 -19 -20 -29 -32 -17
-15 15 -2 43 17 36 8 -3 15 -12 15 -19z"/>
<path d="M3396 6121 c-3 -5 -10 -40 -16 -77 -75 -529 -237 -1090 -416 -1445
-54 -109 -191 -325 -227 -361 -1 -1 -150 -5 -331 -8 -292 -4 -330 -7 -339 -22
-8 -12 -7 -21 2 -32 12 -14 143 -16 1357 -16 l1343 0 11 21 c9 15 8 24 -1 35
-9 12 -66 14 -332 14 l-320 0 -31 33 c-122 129 -288 465 -410 827 -83 248
-176 650 -211 913 -8 60 -17 113 -20 118 -8 12 -52 12 -59 0z m99 -626 c10
-44 37 -150 61 -234 24 -85 42 -156 40 -158 -2 -2 -32 16 -67 39 l-64 43 -2
210 c-2 120 1 204 6 195 5 -8 16 -51 26 -95z m-105 -104 l0 -199 -57 -41 c-48
-34 -83 -50 -83 -38 0 1 20 73 45 161 24 87 52 194 62 237 9 44 20 79 25 79 4
0 8 -90 8 -199z m-2 -383 l-3 -81 -128 -89 c-71 -49 -131 -87 -134 -85 -2 3
15 54 38 114 l43 108 80 57 c45 31 87 56 94 57 10 1 12 -19 10 -81z m172 25
c74 -51 86 -64 103 -108 10 -27 30 -76 43 -108 13 -32 24 -60 24 -63 0 -12
-25 3 -140 84 l-125 88 -3 82 c-2 45 1 82 6 82 4 0 46 -26 92 -57z m-170 -285
c0 -46 -4 -88 -8 -94 -6 -10 -167 -114 -176 -114 -6 0 -89 110 -85 114 2 2 31
22 64 45 33 22 87 61 120 85 33 24 66 45 73 45 9 1 12 -22 12 -81z m186 11
c49 -34 104 -73 121 -86 l33 -24 -42 -55 c-22 -30 -46 -54 -52 -52 -6 2 -48
27 -93 57 l-83 53 0 90 c0 73 3 89 14 85 7 -3 54 -33 102 -68z m-471 -211 l39
-51 -23 -16 c-108 -74 -201 -129 -201 -120 0 6 15 37 34 68 19 31 47 82 62
114 16 31 33 57 39 57 5 -1 28 -24 50 -52z m724 -3 c15 -28 44 -81 65 -118 21
-37 36 -70 34 -72 -4 -4 -193 117 -212 135 -10 10 63 112 78 107 5 -2 21 -25
35 -52z m-439 -111 c0 -63 -3 -114 -8 -114 -9 0 -19 12 -79 91 -24 31 -43 60
-43 65 0 12 113 83 123 77 4 -2 7 -56 7 -119z m141 86 c32 -23 59 -43 59 -46
0 -10 -114 -153 -122 -154 -5 0 -8 54 -8 120 0 66 2 120 6 120 3 0 32 -18 65
-40z m-262 -185 c38 -49 67 -93 65 -97 -7 -12 -422 -10 -429 1 -7 12 264 192
282 189 6 -2 43 -43 82 -93z m542 4 c77 -50 139 -95 139 -100 0 -12 -427 -12
-434 0 -4 5 13 33 37 63 23 29 55 70 70 91 15 20 33 37 39 37 6 0 73 -41 149
-91z"/>
<path d="M2321 6006 c-19 -23 -4 -56 25 -56 28 0 42 25 27 49 -14 22 -37 25
-52 7z m47 -23 c-5 -26 -38 -29 -38 -4 0 15 6 21 21 21 14 0 19 -5 17 -17z"/>
<path d="M4267 5574 c-25 -25 8 -70 43 -59 32 10 24 59 -10 63 -14 2 -29 0
-33 -4z m51 -27 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25 33 22 38 -4z"/>
<path d="M4022 5527 c-97 -36 -152 -125 -152 -251 0 -95 13 -143 52 -191 40
-48 85 -65 175 -65 104 0 153 27 208 115 28 43 34 209 11 270 -41 107 -178
164 -294 122z m130 -88 c32 -17 48 -71 48 -164 0 -127 -26 -175 -97 -175 -68
0 -103 59 -103 172 0 90 14 143 43 163 25 17 79 20 109 4z"/>
<path d="M4560 5529 c-56 -17 -100 -58 -129 -118 -22 -48 -26 -69 -26 -151 0
-79 4 -103 23 -142 28 -57 60 -84 115 -99 46 -12 104 0 132 27 22 22 29 12 21
-30 -4 -22 -20 -46 -43 -66 l-36 -32 18 -34 c10 -19 22 -34 27 -34 22 0 69 33
102 72 l36 42 0 158 0 158 -55 0 -55 0 0 -45 c0 -50 -30 -119 -58 -133 -26
-14 -59 3 -79 40 -13 25 -18 56 -18 123 0 103 6 132 35 163 28 30 97 30 135 1
l27 -22 24 29 c31 38 30 45 -10 72 -40 28 -131 37 -186 21z"/>
<path d="M7125 5529 c-63 -18 -105 -73 -105 -137 0 -58 34 -101 111 -142 87
-46 99 -57 99 -91 0 -20 -8 -34 -26 -46 -26 -16 -29 -16 -88 5 -33 12 -65 23
-70 25 -5 1 -16 -15 -24 -37 -15 -36 -14 -39 4 -50 42 -25 82 -35 143 -35 118
-2 191 58 191 156 0 69 -25 101 -117 149 -56 29 -81 48 -87 67 -19 53 41 74
119 41 34 -14 38 -14 51 3 38 50 31 64 -50 88 -55 17 -105 18 -151 4z"/>
<path d="M8737 5530 c-50 -15 -98 -61 -125 -119 -22 -47 -27 -70 -27 -146 0
-77 4 -97 25 -139 15 -30 41 -59 64 -75 36 -24 48 -26 142 -26 120 0 132 6
114 59 -10 32 -13 34 -36 26 -47 -18 -109 -12 -137 14 -58 54 -59 252 -2 309
21 22 84 22 114 1 22 -16 24 -15 54 14 29 30 30 32 14 50 -33 36 -133 52 -200
32z"/>
<path d="M9137 5530 c-44 -13 -94 -56 -124 -107 -21 -36 -27 -62 -31 -124 -11
-186 61 -279 216 -279 84 0 127 13 169 53 55 51 76 109 76 208 0 74 -4 95 -26
141 -28 57 -61 87 -116 106 -39 14 -122 14 -164 2z m125 -91 c33 -18 48 -71
48 -169 0 -126 -25 -170 -98 -170 -40 0 -79 33 -92 78 -23 82 -8 212 28 255
17 19 82 23 114 6z"/>
<path d="M4900 5275 l0 -255 165 0 165 0 0 45 0 45 -105 0 -105 0 0 65 0 64
77 3 78 3 6 37 c3 21 4 40 1 43 -3 3 -41 5 -84 5 l-79 0 3 58 3 57 98 0 97 0
0 43 0 42 -160 0 -160 0 0 -255z"/>
<path d="M5300 5275 l0 -255 111 0 c127 0 173 11 223 52 52 43 68 81 74 181 6
107 -13 165 -70 219 -50 48 -88 58 -225 58 l-113 0 0 -255z m214 163 c44 -19
60 -55 64 -148 5 -109 -11 -154 -64 -176 -20 -8 -48 -14 -63 -12 l-26 3 -3
173 -2 172 34 0 c19 0 46 -5 60 -12z"/>
<path d="M5907 5523 c-9 -15 -187 -485 -187 -494 0 -5 26 -9 58 -9 l58 0 15
58 15 57 91 3 90 3 17 -61 17 -60 60 0 c32 0 59 2 59 4 0 1 -11 34 -25 72 -14
38 -55 151 -92 252 l-66 182 -53 0 c-29 0 -55 -3 -57 -7z m82 -207 c12 -37 21
-72 21 -77 0 -10 -93 -13 -102 -3 -3 3 6 41 20 84 14 44 29 76 33 72 4 -4 17
-38 28 -76z"/>
<path d="M6160 5490 l0 -39 58 -3 57 -3 3 -212 2 -213 60 0 60 0 0 216 0 215
55 -2 c30 -1 57 0 60 3 3 3 9 22 12 42 l6 36 -187 0 -186 0 0 -40z"/>
<path d="M6679 5503 c-36 -92 -168 -442 -174 -461 -6 -22 -5 -23 51 -20 l58 3
19 58 20 57 88 0 88 0 17 -60 17 -60 58 0 c33 0 59 2 59 5 0 2 -15 46 -34 97
-30 79 -106 290 -138 381 -9 25 -14 27 -64 27 -50 0 -55 -2 -65 -27z m94 -189
c14 -40 23 -75 20 -78 -8 -8 -98 -8 -105 0 -3 3 6 41 21 84 14 43 29 75 32 72
3 -4 18 -39 32 -78z"/>
<path d="M7422 5343 c5 -199 10 -225 55 -271 37 -38 81 -52 161 -52 84 0 143
27 180 83 27 40 27 43 30 234 l4 193 -61 0 -61 0 0 -169 c0 -214 -13 -251 -86
-251 -89 0 -104 37 -104 255 l0 165 -61 0 -61 0 4 -187z"/>
<path d="M7940 5276 l0 -256 108 0 c125 0 174 11 215 50 23 21 31 39 34 77 5
45 2 53 -27 86 -18 21 -43 40 -56 43 -22 6 -21 7 19 38 68 52 68 131 0 183
-35 27 -42 28 -165 32 l-128 3 0 -256z m211 152 c13 -20 11 -72 -4 -91 -6 -10
-29 -20 -50 -24 l-37 -6 0 70 c0 38 2 72 6 75 9 9 76 -10 85 -24z m-1 -208
c23 -23 28 -91 8 -108 -7 -5 -31 -12 -53 -14 l-40 -3 -3 73 -3 72 36 0 c22 0
43 -8 55 -20z"/>
<path d="M9556 5518 c-3 -7 -10 -114 -16 -238 -6 -124 -14 -233 -17 -243 -4
-16 1 -18 54 -15 l58 3 5 140 c4 123 7 137 18 112 6 -16 12 -31 12 -33 0 -3
11 -29 23 -57 13 -29 34 -78 47 -109 l24 -58 47 0 47 0 45 108 c85 201 75 194
76 50 1 -69 4 -133 7 -142 5 -12 20 -16 60 -16 45 0 54 3 54 18 0 15 -10 176
-26 410 l-6 82 -51 0 -52 0 -67 -173 c-37 -94 -69 -180 -72 -190 -6 -19 -26
-14 -26 6 -1 7 -31 89 -69 182 l-68 170 -52 3 c-35 2 -52 -1 -55 -10z"/>
<path d="M8397 5122 c-25 -27 -22 -78 5 -96 29 -21 51 -20 78 2 25 21 30 73 9
98 -16 20 -73 18 -92 -4z"/>
<path d="M1769 4214 c-9 -11 -10 -20 -2 -32 15 -24 44 -27 59 -7 28 38 -27 75
-57 39z"/>
<path d="M1889 4214 c-23 -27 1 -49 59 -52 48 -3 52 -1 58 22 3 14 3 30 -1 35
-10 18 -101 13 -116 -5z"/>
<path d="M4841 4212 c-17 -32 5 -52 59 -52 54 0 76 20 59 52 -7 14 -22 18 -59
18 -37 0 -52 -4 -59 -18z"/>
<path d="M5020 4209 c-9 -15 -8 -24 1 -35 27 -33 79 1 58 39 -14 23 -46 21
-59 -4z"/>
<path d="M2734 4072 c-17 -11 -22 -35 -12 -54 7 -15 23 -18 98 -18 48 0 95 3
104 6 19 7 21 45 4 62 -13 13 -175 17 -194 4z"/>
<path d="M3004 4059 c-14 -15 -16 -24 -8 -37 10 -15 36 -18 180 -22 150 -4
169 -7 172 -22 3 -16 -6 -18 -82 -18 -64 0 -88 -4 -100 -16 -9 -8 -16 -19 -16
-24 0 -5 7 -16 16 -24 23 -24 515 -24 538 0 18 18 19 23 6 44 -7 11 -34 16
-110 18 -90 4 -100 6 -100 23 0 17 11 19 173 21 l172 3 3 24 c7 50 -1 51 -428
51 -388 0 -397 0 -416 -21z"/>
<path d="M3926 4067 c-11 -8 -17 -22 -14 -38 3 -24 5 -24 102 -24 83 0 101 3
110 18 8 12 8 22 0 35 -15 23 -169 30 -198 9z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
